/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import '../../VodafoneIdea.scss';
import {
  useExcelJsonList,
  useLoadRomsFormFieldListData,
  useLoadFieldValues,
  useLoadCompanySeal,
} from '../../../../../../hooks/form.hooks';
import { loadImageBlob } from '../../../../../../utils/aws.util';
import { useAuthContext } from '../../../../../../context/auth.context';
import { Spinner, Center, useTheme } from '@chakra-ui/react';
import { encode } from '../../cafCrf.util';
import { JsonToTable } from 'react-json-to-table';
import VodafoneImg from '../../../../../../assets/images/vodafone.jpg';
import IdeaImg from '../../../../../../assets/images/idealogo.jpg';
import CloudImg from '../../../../../../assets/images/cloud.jpg';
import lotImg from '../../../../../../assets/images/lot.jpg';
import WireImg from '../../../../../../assets/images/wire.jpg';
import WirelessImg from '../../../../../../assets/images/wireless.jpg';

const IllCafForm = ({ formData, queueData, romsActivity, submittedForms }) => {
  const [cafData, setCafData] = useState(false);
  const [illCafDataupdated, setUpdatedCafData] = useState(null);
  const [companySeal, setCompanySeal] = useState(null);
  const [photoGraph, setPhotograph] = useState(null);
  const [authorizedSignatoryName, setAuthSignName] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [imageType, setImageType] = useState(null);
  const [acctMngSignImageData, setacctMngSignImageData] = useState(null);
  const [acctMngSignImageType, setacctMngSignImageType] = useState(null);
  const [photoGraphImageData, setphotoGraphImageData] = useState(null);
  const [photoGraphImageType, setphotoGraphImageType] = useState(null);
  const [excelJson, setexcelJson] = useState(null);

  const { mutate } = useLoadRomsFormFieldListData();
  const { mutate: loadOrderFormValues } = useLoadFieldValues();
  const { mutate: loadExcelJsonValues } = useExcelJsonList();
  const { mutate: loadCompanySeal } = useLoadCompanySeal();

  let componentRef = React.useRef();
  const chakraTheme = useTheme();

  const {
    state: { authData },
  } = useAuthContext();

  const fetchFieldListData = () => {
    mutate(
      {
        fieldList: {
          organization_id: authData.organization_id,
          account_id: authData.account_id,
          workforce_id: authData.workforce_id,
          form_id: formData.form_id,
        },
        fieldValues: {
          form_id: formData.form_id,
          form_transaction_id: formData.form_transaction_id
            ? formData.form_transaction_id
            : formData.data_form_transaction_id,
          start_from: 0,
          limit_value: 50,
        },
      },
      {
        onSuccess: async data => {
          let finalFields = data;
          setCafData(false);
          setUpdatedCafData(finalFields);
        },
        onError: async err => {
          console.log('error occurred while loading fields', err);
        },
      }
    );
  };

  const fetchOrderFormValues = () => {
    let orderForm = submittedForms.find(
      item => item.form_id === romsActivity.order_form_id
    );
    loadOrderFormValues(
      {
        form_id: romsActivity?.order_form_id,
        form_transaction_id: !!orderForm
          ? orderForm.data_form_transaction_id
          : 0,
      },
      {
        onSuccess: async res => {
          if (res.length > 0) {
            let ExcelIndex = res[0].activity_inline_data.findIndex(
              res1 => res1.field_id === 12949
            );
            let excelUrl;
            if (ExcelIndex > -1) {
              excelUrl = res[0].activity_inline_data[ExcelIndex].field_value;
            }
            if (!!excelUrl) {
              loadExcelJsonValues(
                { bucket_url: excelUrl },
                {
                  onSuccess: async result => {
                    setexcelJson(JSON.parse(result));
                  },
                }
              );
            }
          }
        },
      }
    );
  };

  const fetchCompanySeal = () => {
    loadCompanySeal(
      {
        form_id: 1115,
        field_id: 0,
        activity_id: queueData.activity_id,
      },
      {
        onSuccess: async res => {
          let companySeal = '';
          let photoGraph = '';
          let authorizedSignatoryName = '';
          let data = [];
          if (res.length > 0) {
            data = JSON.parse(res[0].data_entity_inline).form_submitted;
          } else {
            data = [];
          }
          data.forEach(function (fieldObj, index) {
            if (Number(fieldObj.field_id) === 8613) {
              companySeal = fieldObj.field_value;
            }
            if (Number(fieldObj.field_id) === 9038) {
              photoGraph = fieldObj.field_value;
            }
            if (Number(fieldObj.field_id) === 8612) {
              authorizedSignatoryName = fieldObj.field_value;
            }
          });
          setCompanySeal(companySeal);
          setPhotograph(photoGraph);
          setAuthSignName(authorizedSignatoryName);
        },
      }
    );
  };

  useEffect(() => {
    setCafData(true);
    fetchCompanySeal();
    fetchFieldListData();
    fetchOrderFormValues();
  }, []);

  useEffect(() => {
    let data = illCafDataupdated;
    if (!!data) {
      if (!!companySeal) {
        // customer company seal
        let custCompanySeal = companySeal;
        loadImageBlob(custCompanySeal, (data, type) => {
          setImageData(data);
          setImageType(type);
        });
      }
      if (!!data[8828].value) {
        // manager sign url
        let acctManagerSignUrl = data[8828].value;
        loadImageBlob(acctManagerSignUrl, (data, type) => {
          setacctMngSignImageData(data);
          setacctMngSignImageType(type);
        });
      }
      if (!!photoGraph) {
        // customer photograph url
        let photographUrl = photoGraph;
        loadImageBlob(photographUrl, (data, type) => {
          setphotoGraphImageData(data);
          setphotoGraphImageType(type);
        });
      }
    }
  }, [illCafDataupdated, companySeal, photoGraph, authorizedSignatoryName]);

  let illCafContentView = '';
  if (!!illCafDataupdated) {
    let firstCaps = '';
    let secondBold;
    let Signcss;
    let Overlapcss = 'signate float_img_illcaf';
    if (!!authorizedSignatoryName) {
      let customerSignSplit = authorizedSignatoryName.split(' ');

      if (customerSignSplit.length > 1) {
        firstCaps = customerSignSplit['0'].slice(0, 1).toUpperCase();
        secondBold = customerSignSplit['1'];
        Signcss = 'signate_img input_sign';
      } else {
        firstCaps = customerSignSplit['0'];
        secondBold = '';
        Signcss = 'signate_img input_sign under_line';
      }
    }

    illCafContentView = (
      <div>
        <div
          style={{ textAlign: 'left' }}
          className="tab_content"
          ref={el => (componentRef = el)}
        >
          <page size="A4">
            <div className="section1">
              <div className="section1_top">
                <div className="section1_top_col">
                  <p className="pad_l_r10 over_hid">
                    <img src={VodafoneImg} />
                    <img src={IdeaImg} className="pullr" />
                  </p>
                  <p className="customhed">Customer Application Form</p>
                  <div className="empty1"></div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <p className="vodabar">
                    Vodafone Idea Business Services
                    <br />
                    Internet Leased Line
                  </p>
                </div>
              </div>
            </div>
            <div className="signate float_img">
              {firstCaps !== '' ? (
                <div style={{ padding: '10px' }} className={Signcss}>
                  {firstCaps + '  .  '}
                  <span className="under_line">{secondBold}</span>
                </div>
              ) : (
                ''
              )}
              {imageData !== null && imageData !== '' ? (
                <div style={{ padding: '10px' }} className="signate_img">
                  <img
                    src={
                      imageData !== null
                        ? 'data:' + imageType + ';base64,' + encode(imageData)
                        : ''
                    }
                    alt={'Company Seal'}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
          </page>
          <page size="A4">
            <div className="section2">
              <div className="font8">
                <p className="over_hid">
                  <img src={VodafoneImg} />
                  <img src={IdeaImg} className="pullr" />
                </p>
                <p className="customfom">Product Form - Internet Leased Line</p>
                <div className="">
                  <div className="over_hid">
                    <div className="pd_t5">
                      <strong>Vodafone Idea Business Servies</strong>{' '}
                      <span className="pad_l10">
                        Kindly fill the form in BLOCK letters
                      </span>
                      <div className="caf">
                        CAF No.
                        <input
                          className="form_col brd_b"
                          type="text"
                          name=""
                          value={illCafDataupdated[8616].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="over_hid">
                    <div className="width75 fleft">
                      <div>
                        <strong>Company(Customer) Name</strong>{' '}
                        <span className="pad_l10">
                          <input
                            className="form_col_input width71 input_red"
                            type="text"
                            name=""
                            value={illCafDataupdated[8617].value}
                            readOnly
                          />
                        </span>
                      </div>
                      <div className="">
                        <strong>Account Code</strong>{' '}
                        <span className="pad_l10">
                          <input
                            className="form_col_input width83"
                            type="text"
                            name=""
                            value={illCafDataupdated[8618].value}
                            readOnly
                          />
                        </span>
                      </div>

                      <div className="pad_t2">
                        {' '}
                        <p className="sign">
                          <strong>Authorised Signatory Details</strong>
                        </p>{' '}
                      </div>
                      <div className="pad_t2">
                        {' '}
                        Name{' '}
                        <span className="pad_l10">
                          <input
                            className="form_col_input width91"
                            type="text"
                            name=""
                            value={illCafDataupdated[8620].value}
                            readOnly
                          />
                        </span>
                      </div>
                      <div className="over_hid auth">
                        <div className="fleft width50">
                          Contact No:
                          <input
                            className="form_col width59"
                            type="text"
                            name=""
                            value={illCafDataupdated[8621].value}
                            readOnly
                          />
                        </div>
                        <div className="fleft width50">
                          Email:
                          <input
                            className="form_col width80"
                            type="text"
                            name=""
                            value={illCafDataupdated[8622].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="width20 flright">
                      {/* <div className="mand">*Mandatory</div> */}
                      <div className="crossign" style={{ height: '125px' }}>
                        {photoGraphImageData !== null &&
                        photoGraphImageData !== '' ? (
                          <div
                            style={{
                              padding: '5px',
                              position: 'relative',
                              textAlign: 'center',
                            }}
                            className="signate_img"
                          >
                            <img
                              style={{ height: '90px' }}
                              src={
                                photoGraphImageData !== null
                                  ? 'data:' +
                                    photoGraphImageType +
                                    ';base64,' +
                                    encode(photoGraphImageData)
                                  : ''
                              }
                              alt={'PhotoGraph with Crosssign'}
                            />
                            {firstCaps !== '' ? (
                              <span
                                style={{
                                  padding: '10px',
                                  // position: 'absolute',
                                  left: '-10px',
                                  top: '8px',
                                }}
                                className={Signcss}
                              >
                                {firstCaps + '  .  '}
                                <span className="under_line">{secondBold}</span>
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <p className="font9 margin0">
                  I/We hereby order for VIL Internet Leased Line Service subject
                  to terms and conditions as described in the service order
                  form. We furnish the necessary details as follow.
                </p>
                <p className="ligthbg">BILLING ADDRESS</p>
                <p className="auth margin0">
                  Contact Person name
                  <input
                    className="form_col width60"
                    type="text"
                    name=""
                    value={illCafDataupdated[8626].value}
                    readOnly
                  />
                </p>
                <div className="auth margin0 over_hid">
                  <div className="fleft width50">
                    Designation
                    <input
                      className="form_col width80"
                      type="text"
                      name=""
                      value={illCafDataupdated[8627].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width50">
                    Dept. Name
                    <input
                      className="form_col width80"
                      type="text"
                      name=""
                      value={illCafDataupdated[8628].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth margin0">
                  <span className="width54px">Address</span>
                  <input
                    className="form_col width90"
                    type="text"
                    name=""
                    value={illCafDataupdated[8629].value}
                    readOnly
                  />
                </div>
                <div className="auth margin0">
                  <span className="width54px"> </span>
                  <input
                    className="form_col width90"
                    type="text"
                    name=""
                    value=""
                  />
                </div>
                <div className="auth margin0 over_hid">
                  <div className="fleft width40">
                    City/Village/Post office
                    <input
                      className="form_col width54"
                      type="text"
                      name=""
                      value={illCafDataupdated[8630].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width20">
                    State
                    <input
                      className="form_col width65"
                      type="text"
                      name=""
                      value={illCafDataupdated[8631].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width20">
                    Pincode
                    <input
                      className="form_col width65"
                      type="text"
                      name=""
                      value={illCafDataupdated[8632].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width20">
                    Telephone
                    <input
                      className="form_col width58"
                      type="text"
                      name=""
                      value={illCafDataupdated[8633].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth over_hid">
                  <div className="fleft width58">
                    Landmark
                    <input
                      className="form_col width84"
                      type="text"
                      name=""
                      value={illCafDataupdated[8634].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width42">
                    <span className="faxn">Fax No.</span>
                    <input
                      className="form_col width66"
                      type="text"
                      name=""
                      value={illCafDataupdated[8635].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth over_hid">
                  <div className="fleft width58">
                    Contact Email Id
                    <input
                      className="form_col width77"
                      type="text"
                      name=""
                      value={illCafDataupdated[8636].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width42">
                    Mobile No / Alt No.
                    <input
                      className="form_col width66"
                      type="text"
                      name=""
                      value={illCafDataupdated[8637].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="ligthbg">INSTALLATION ADDRESS</p>
                <p className="font9">
                  I/We hereby order for VIL Internet Leased Line Service subject
                  to terms and conditions as described in the service order
                  form. We furnish the necessary details as follow.
                  <br />
                  This Address is for installation
                </p>
                <p className="auth">
                  Contact Person name
                  <input
                    className="form_col width60"
                    type="text"
                    name=""
                    value={illCafDataupdated[8640].value}
                    readOnly
                  />
                </p>
                <div className="auth over_hid">
                  <div className="fleft width50">
                    Designation
                    <input
                      className="form_col width80"
                      type="text"
                      name=""
                      value={illCafDataupdated[8641].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width50">
                    Dept. Name
                    <input
                      className="form_col width80"
                      type="text"
                      name=""
                      value={illCafDataupdated[8642].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth margin0">
                  <span className="width54px">Address</span>
                  <input
                    className="form_col width90"
                    type="text"
                    name=""
                    value={illCafDataupdated[8643].value}
                    readOnly
                  />
                </div>
                <div className="auth margin0">
                  <span className="width54px"> </span>
                  <input
                    className="form_col width90"
                    type="text"
                    name=""
                    value=""
                  />
                </div>
                <div className="auth margin0 over_hid">
                  <div className="fleft width40">
                    City/Village/Post office
                    <input
                      className="form_col width54"
                      type="text"
                      name=""
                      value={illCafDataupdated[8644].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width20">
                    State
                    <input
                      className="form_col width65"
                      type="text"
                      name=""
                      value={illCafDataupdated[8645].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width20">
                    Pincode
                    <input
                      className="form_col width65"
                      type="text"
                      name=""
                      value={illCafDataupdated[8646].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width20">
                    Telephone
                    <input
                      className="form_col width58"
                      type="text"
                      name=""
                      value={illCafDataupdated[8647].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth over_hid">
                  <div className="fleft width55">
                    Landmark
                    <input
                      className="form_col width80"
                      type="text"
                      name=""
                      value={illCafDataupdated[8648].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width25">
                    Telecom Circle
                    <input
                      className="form_col width55"
                      type="text"
                      name=""
                      value={illCafDataupdated[8649].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width20">
                    <span className="faxn widthauto">Fax No.</span>
                    <input
                      className="form_col width65"
                      type="text"
                      name=""
                      value={illCafDataupdated[8650].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth over_hid">
                  <div className="fleft width58">
                    Contact Email Id
                    <input
                      className="form_col width77"
                      type="text"
                      name=""
                      value={illCafDataupdated[8651].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width42">
                    Mobile No / Alt No.
                    <input
                      className="form_col width66"
                      type="text"
                      name=""
                      value={illCafDataupdated[8652].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid">
                  <div className="fleft width20 pad_t7"></div>
                  <div className="fleft width15">
                    GSTIN
                    {illCafDataupdated[8653].value === 'GSTIN' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                  </div>
                  <div className="fleft width15">
                    UIN
                    {illCafDataupdated[8653].value === 'UIN' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                  </div>
                  <div className="fleft width15">
                    GST_ISD
                    {illCafDataupdated[8653].value === 'GST_ISD' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                  </div>
                </div>
                <div className="check over_hid typecheck">
                  <div className="fleft width12 pad_t7">Customer Type</div>
                  <div className="fleft width12">
                    {illCafDataupdated[8654].value === 'Normal' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Normal
                  </div>
                  <div className="fleft width12">
                    {illCafDataupdated[8654].value === 'SEZ' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    SEZ
                  </div>
                  <div className="fleft width12">
                    {illCafDataupdated[8654].value === 'Diploma' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Diplomat
                  </div>
                  <div className="fleft width12">
                    {illCafDataupdated[8654].value === 'Embassy' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Embassy
                  </div>
                  <div className="fleft width12">
                    {illCafDataupdated[8654].value === 'Consulate' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Consulate
                  </div>
                  <div className="fleft width12">
                    {illCafDataupdated[8654].value === 'Special Agency' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Special Agency
                  </div>
                  <div className="fleft width12">
                    {illCafDataupdated[8654].value ===
                    'Person/Class of Persons' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Person/Class of Persons
                  </div>
                </div>
                <p className="auth">
                  GSTIN / UIN / GST_ISD No
                  <input
                    className="form_col wid50"
                    type="text"
                    name=""
                    value={illCafDataupdated[8655].value}
                    readOnly
                  />
                </p>
                <div className="auth">
                  <span className="width70px in_block">GST Reg. Address</span>
                  <input
                    className="form_col width78"
                    type="text"
                    name=""
                    value={illCafDataupdated[8656].value}
                    readOnly
                  />
                </div>
                <div className="auth">
                  <span className="width70px in_block">
                    in installation state
                  </span>
                  <input
                    className="form_col width78"
                    type="text"
                    name=""
                    value=""
                  />
                </div>
                <div className="check over_hid typecheck gstcheck">
                  <div className="fleft width12 pad_t7">GST Customer Dept</div>
                  <div className="fleft width12">
                    {illCafDataupdated[8657].value === 'IT' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    IT
                  </div>
                  <div className="fleft width12">
                    {illCafDataupdated[8657].value === 'Tech' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Tech
                  </div>
                  <div className="fleft width12">
                    {illCafDataupdated[8657].value === 'Cust Service' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Cust Service
                  </div>
                  <div className="fleft width12">
                    {illCafDataupdated[8657].value === 'Legal' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Legal
                  </div>
                  <div className="fleft width12">
                    {illCafDataupdated[8657].value === 'Finance' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Finance
                  </div>
                  <div className="fleft width12">
                    {illCafDataupdated[8657].value === 'Purchase' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Purchase
                  </div>
                  <div className="fleft width12">
                    {illCafDataupdated[8657].value === 'SCM' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    SCM
                  </div>
                  <div className="fleft width12">
                    {illCafDataupdated[8657].value === 'Delivery' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Delivery
                  </div>
                  <div className="fleft width12">
                    {illCafDataupdated[8657].value === 'Others' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Others
                  </div>
                  <div className="fleft width12">
                    <input
                      className="form_col oth_gst"
                      type="text"
                      name=""
                      value=""
                    />
                  </div>
                </div>
                <p className="auth namsupp">
                  Name of Supporting Document
                  <input
                    className="form_col wid50"
                    type="text"
                    name=""
                    value={illCafDataupdated[8658].value}
                    readOnly
                  />
                </p>
                <p className="gsttext">
                  (GST Certificate, SEZ Certificate, Diplomat Crtificate)
                </p>
                <p className="ligthbg">CUSTOMER INFORMATION</p>
                <div className="check over_hid custype">
                  <div className="fleft width15_2 pad_7t">Customer Type</div>
                  <div className="fleft width14">
                    {illCafDataupdated[8660].value === 'General' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    General
                  </div>
                  {/* <div className="fleft width14">
                                        {illCafDataupdated[8660].value === "ISP" ?
                                             <input className="form_col" type="checkbox" name="" value="" checked  readOnly /> : 
                                             <input className="form_col" type="checkbox" name="" value="" disabled />
                                        }ISP</div>
                                    <div className="fleft width14">
                                        {illCafDataupdated[8660].value === "OSP License No" ?
                                             <input className="form_col" type="checkbox" name="" value="" checked  readOnly /> : 
                                             <input className="form_col" type="checkbox" name="" value="" disabled />
                                        }OSP License No</div>
                                    <div className="fleft width14">
                                        {illCafDataupdated[8660].value === "OSP Reg No" ?
                                             <input className="form_col" type="checkbox" name="" value="" checked  readOnly /> : 
                                             <input className="form_col" type="checkbox" name="" value="" disabled />
                                        }OSP Reg No</div> */}
                  <div className="fleft width14">
                    ISP License No
                    <input
                      className="form_col band_v1"
                      type="text"
                      name=""
                      value={illCafDataupdated[8662].value}
                      disabled
                    />
                  </div>
                  <div className="fleft width14">
                    Date
                    <input
                      className="form_col band_v1"
                      type="text"
                      name=""
                      value={illCafDataupdated[8663].value}
                      disabled
                    />
                  </div>
                  <div className="fleft width14">
                    OSP Reg No
                    <input
                      className="form_col band_v1"
                      type="text"
                      name=""
                      value={illCafDataupdated[8665].value}
                      disabled
                    />
                  </div>
                  <div className="fleft width14">
                    Date
                    <input
                      className="form_col band_v1"
                      type="text"
                      name=""
                      value={illCafDataupdated[8666].value}
                      disabled
                    />
                  </div>
                </div>
                <div className="check over_hid custype">
                  <div className="fleft width15_2 pad_7t"> </div>
                  <div className="fleft width14">
                    {illCafDataupdated[8660].value === 'Telemarketer' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Telemarketer
                  </div>
                  <div className="fleft width14">
                    {illCafDataupdated[8660].value === 'BPO' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    BPO
                  </div>
                  <div className="fleft width14">
                    {illCafDataupdated[8660].value === 'Others' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Others
                  </div>
                </div>

                <div className="check over_hid custype">
                  <div className="fleft width15_2 pad_7t">
                    Customer Vertical Type
                  </div>
                  <div className="fleft width14">
                    {illCafDataupdated[8667].value === 'Automotive' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Automotive
                  </div>
                  <div className="fleft width14">
                    {illCafDataupdated[8667].value === 'Consumer Goods' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Consumer Goods
                  </div>
                  <div className="fleft width14">
                    {illCafDataupdated[8667].value ===
                    'Diversified & Projects' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Diversified & Projects
                  </div>
                  <div className="fleft width14">
                    {illCafDataupdated[8667].value === 'Education' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Education
                  </div>
                  <div className="fleft width14">
                    {illCafDataupdated[8667].value === 'Engineering' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Engineering
                  </div>
                  <div className="fleft width14 fnt8">
                    {illCafDataupdated[8667].value ===
                    'Industry Associations' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Industry Associations
                  </div>
                </div>
                <div className="check over_hid custype">
                  <div className="fleft width15_2 pad_7t"> </div>
                  <div className="fleft width14">
                    {illCafDataupdated[8667].value === 'Health Care' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Health Care
                  </div>
                  <div className="fleft width14">
                    {illCafDataupdated[8667].value === 'Finance' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Finance
                  </div>

                  <div className="fleft width14">
                    {illCafDataupdated[8667].value === 'Government' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Government
                  </div>
                  <div className="fleft width14">
                    {illCafDataupdated[8667].value === 'IT & Telecom' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    IT & Telecom
                  </div>
                  <div className="fleft width14">
                    {illCafDataupdated[8667].value === 'Logistics' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Logistics
                  </div>
                  <div className="fleft width14 fnt6">
                    {illCafDataupdated[8667].value ===
                    'Manufacturing & Processing' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Manufacturing & Processing
                  </div>
                </div>
                <div className="check over_hid custype">
                  <div className="fleft width15_2 pad_7t"> </div>
                  <div className="fleft width14">
                    {illCafDataupdated[8667].value === 'Trading' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Trading
                  </div>
                  <div className="fleft width14">
                    {illCafDataupdated[8667].value === 'Services' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Services
                  </div>
                  <div className="fleft width14">
                    {illCafDataupdated[8667].value === 'Projects EPC' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Projects EPC
                  </div>
                  <div className="fleft width14">
                    {illCafDataupdated[8667].value === 'Others' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Others
                  </div>
                </div>
                <p className="ligthbg">SERVICE ORDER DETAILS</p>
                <div className="signate float_img">
                  {firstCaps !== '' ? (
                    <div style={{ padding: '0px' }} className={Signcss}>
                      {firstCaps + '  .  '}
                      <span className="under_line">{secondBold}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {imageData !== null && imageData !== '' ? (
                    <div style={{ padding: '0px' }} className="signate_img">
                      <img
                        src={
                          imageData !== null
                            ? 'data:' +
                              imageType +
                              ';base64,' +
                              encode(imageData)
                            : ''
                        }
                        alt={'Company Seal'}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="check over_hid mpls">
                  <div className="fleft width70px pad_7t">
                    <strong>Type of Order</strong>
                  </div>
                  <div className="fleft width14">
                    {illCafDataupdated[8669].value === 'New' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    New
                  </div>

                  <div className="fleft width14">
                    {illCafDataupdated[8669].value === 'POC' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    POC
                  </div>
                </div>
                <div className="over_hid mplstextin padb10">
                  <div className="fleft padt5 pad_r5">
                    <strong>
                      Feasibility ID (FR ID) Year on year Discount{' '}
                    </strong>
                  </div>
                  <div className="fleft width70">
                    <input
                      className="form_col width70"
                      type="text"
                      name=""
                      value={illCafDataupdated[8670].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid">
                  <div className="fleft width70px pad_7t">
                    {' '}
                    Service Flavours
                  </div>

                  <div className="fleft width30">
                    {illCafDataupdated[8671].value ===
                    'Business Internet Leased Line(1:1 ILL)' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Business Internet Leased Line(1:1 ILL){' '}
                  </div>
                  <div className="fleft width30">
                    {illCafDataupdated[8671].value ===
                    'Value Plus Internet Leased Line (1:4)' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Value Plus Internet Leased Line (1:4)
                  </div>
                  <div className="fleft">
                    {illCafDataupdated[8671].value ===
                    'Business Internet Port' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Business Internet Port
                  </div>
                </div>
                <div className="check over_hid h30">
                  <div className="fleft width70px pad_7t">
                    Bandwidth Required{' '}
                  </div>
                  <div className="fleft width14">
                    <input
                      className="form_col band_v1 input_red"
                      type="text"
                      name=""
                      value={illCafDataupdated[8672].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width16">
                    {illCafDataupdated[8673].value === 'Kbps' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Kbps{' '}
                  </div>
                  <div className="fleft mplstech">
                    {illCafDataupdated[8673].value === 'Mbps' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Mbps
                  </div>
                </div>
                <div className="auth remarks">
                  <span className="width65px">Remarks</span>
                  <input
                    className="form_col width83"
                    type="text"
                    name=""
                    value={illCafDataupdated[8674].value}
                    readOnly
                  />
                </div>
                <div className="check over_hid">
                  <div className="fleft width70px pad_7t"> Contact Period</div>
                  <div className="fleft width30">
                    <input
                      className="form_col wid120p input_red"
                      type="text"
                      name=""
                      value={illCafDataupdated[8675].value}
                      readOnly
                    />
                    <span className="mnth">Months </span>
                  </div>
                </div>
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2 font8">
              <div className="over_hid">
                <div className="">
                  <div className="pad_b5">
                    <p className="customfom">
                      Product Form - Internet Leased Line
                    </p>
                  </div>
                  <div className="">Year on year Discount</div>
                  <div className="yeardisc">
                    <table
                      width="50%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <tbody>
                        <tr>
                          <td>Year</td>
                          <td>Discount on Previous Year (%)</td>
                          <td>
                            Penalty as percentage of
                            <br />
                            rental of remaining period on
                            <br />
                            breach of contract(%)
                          </td>
                        </tr>
                        <tr>
                          <td>{illCafDataupdated[13320].value}&nbsp;</td>
                          <td>{illCafDataupdated[13321].value}</td>
                          <td>{illCafDataupdated[13322].value}</td>
                        </tr>
                        <tr>
                          <td>{illCafDataupdated[13323].value}&nbsp;</td>
                          <td>{illCafDataupdated[13324].value}</td>
                          <td>{illCafDataupdated[13325].value}</td>
                        </tr>
                        <tr>
                          <td>{illCafDataupdated[13326].value}&nbsp;</td>
                          <td>{illCafDataupdated[13327].value}</td>
                          <td>{illCafDataupdated[13328].value}</td>
                        </tr>
                        <tr>
                          <td>{illCafDataupdated[13329].value}&nbsp;</td>
                          <td>{illCafDataupdated[13330].value}</td>
                          <td>{illCafDataupdated[13331].value}</td>
                        </tr>
                        <tr>
                          <td>{illCafDataupdated[8677].value}&nbsp;</td>
                          <td>{illCafDataupdated[8678].value}</td>
                          <td>{illCafDataupdated[8679].value}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="check over_hid pad_t10">
                    <div className="fleft width70px pad_t3"> Billing In </div>
                    <div className="fleft width16">
                      {illCafDataupdated[8680].value === 'Advance' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Advance
                    </div>
                    <div className="fleft width16">
                      {illCafDataupdated[8680].value === 'Arrears' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Arrears
                    </div>
                  </div>
                  <div className="check over_hid">
                    <div className="fleft width70px pad_t3">
                      {' '}
                      Billing Periodicity{' '}
                    </div>
                    <div className="fleft width16">
                      {illCafDataupdated[8681].value === 'Monthly' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Monthly
                    </div>
                    <div className="fleft width16">
                      {illCafDataupdated[8681].value === 'Quarterly' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Quarterly
                    </div>
                    <div className="fleft width16">
                      {illCafDataupdated[8681].value === 'Half Yearly' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Half Yearly
                    </div>
                    <div className="fleft width16">
                      {illCafDataupdated[8681].value === 'Annually' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Annually
                    </div>
                  </div>
                  <div className="check over_hid">
                    <div className="fleft width70px pad_t3">
                      {' '}
                      CPE Provided By{' '}
                    </div>
                    <div className="fleft width16">
                      {illCafDataupdated[8682].value === 'Vodafone Idea' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Vodafone Idea
                    </div>
                    <div className="fleft width16">
                      {illCafDataupdated[8682].value === 'Customer' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Customer
                    </div>
                  </div>
                  <div className="check over_hid">
                    <div className="fleft width70px pad_t3">
                      {' '}
                      If Provided by Vodafone Idea{' '}
                    </div>
                    <div className="fleft width16">
                      {illCafDataupdated[8683].value === 'Sale' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Sale
                    </div>
                    <div className="fleft width16">
                      {illCafDataupdated[8683].value === 'Rent' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Rent
                    </div>
                  </div>
                  <div className="check over_hid">
                    <div className="fleft width70px pad_t3"> CPE Details </div>
                  </div>
                  <div className="yeardisc">
                    <table
                      width="50%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <tbody>
                        <tr>
                          <td>Sr. No.</td>
                          <td>Type (Standard/Other)</td>
                          <td>Make</td>
                          <td>Model</td>
                          <td>Version</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>{illCafDataupdated[8686].value}</td>
                          <td>{illCafDataupdated[8687].value}</td>
                          <td>{illCafDataupdated[8688].value}</td>
                          <td>{illCafDataupdated[8689].value}</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="check over_hid">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Nth Percentile Required{' '}
                    </div>
                    <div className="fleft width10">
                      {illCafDataupdated[8690].value === 'Yes' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Yes
                    </div>
                    <div className="fleft width10">
                      {illCafDataupdated[8690].value === 'No' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      No
                    </div>
                  </div>
                  <div>IF YES</div>
                  <div className=" over_hid pad_t6">
                    <div className="fleft width170px pad_t3"> Value of N </div>
                    <div className="fleft width15">
                      <input
                        className="form_col width30px"
                        type="text"
                        name=""
                        value={illCafDataupdated[8691].value}
                        readOnly
                      />{' '}
                      ( 1 to 99 )
                    </div>
                    <div className="fleft width60">
                      Maximum Port Speed (Bandwidth)
                      <input
                        className="form_col n_input"
                        type="text"
                        name=""
                        value={illCafDataupdated[8692].value}
                        readOnly
                      />
                      (Kbps/Mbps)
                    </div>
                  </div>
                  <div className="check over_hid">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      BoD Service Requied{' '}
                    </div>
                    <div className="fleft width10">
                      {illCafDataupdated[8693].value === 'Yes' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Yes
                    </div>
                    <div className="fleft width10">
                      {illCafDataupdated[8693].value === 'No' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      No
                    </div>
                  </div>
                  <div>IF YES</div>
                  <div className=" over_hid pad_t2">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Maximum Port Speed(Bandwidth){' '}
                    </div>
                    <div className="fleft width60">
                      <input
                        className="form_col n_input"
                        type="text"
                        name=""
                        value={illCafDataupdated[8694].value}
                        readOnly
                      />
                      (Kbps/Mbps)
                    </div>
                  </div>
                  <div className="check over_hid">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      ToD Service Requied{' '}
                    </div>
                    <div className="fleft width10">
                      {illCafDataupdated[8695].value === 'Yes' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Yes
                    </div>
                    <div className="fleft width10">
                      {illCafDataupdated[8695].value === 'No' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      No
                    </div>{' '}
                  </div>
                  <div>IF YES</div>
                  <div className=" over_hid pad_t2">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Maximum Port Speed(Bandwidth){' '}
                    </div>
                    <div className="fleft width60">
                      <input
                        className="form_col n_input"
                        type="text"
                        name=""
                        value={illCafDataupdated[8696].value}
                        readOnly
                      />
                      (Kbps/Mbps)
                    </div>
                  </div>
                  <div className="check over_hid pd_t3 font9">
                    <div className="fleft width110px pad_t3">
                      {' '}
                      Last Mile details{' '}
                    </div>
                    <div className="fleft width6">
                      {illCafDataupdated[8697].value === 'Media' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Media
                    </div>
                    <div className="fleft width6">
                      {illCafDataupdated[8697].value === 'Copper' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Copper
                    </div>
                    <div className="fleft width6">
                      {illCafDataupdated[8697].value === 'Fiber' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Fiber
                    </div>
                    <div className="fleft width6">
                      {illCafDataupdated[8697].value === 'Wireless' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Wireless
                    </div>
                  </div>
                  <div className="check over_hid pd_t3 font9">
                    <div className="fleft width110px pad_t3">
                      {' '}
                      Primary LM Technology{' '}
                    </div>
                    <div className="fleft width6">
                      {illCafDataupdated[8698].value === 'TDM' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      TDM
                    </div>
                    <div className="fleft width6">
                      {illCafDataupdated[8698].value === 'CEN' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      CEN
                    </div>
                    <div className="fleft width6">
                      {illCafDataupdated[8698].value === 'EOSDH' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      EOSDH
                    </div>
                    <div className="fleft width6">
                      {illCafDataupdated[8698].value === 'BWA' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BWA
                    </div>
                    <div className="fleft width6">
                      {illCafDataupdated[8698].value === 'Radio P2P' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Radio P2P
                    </div>
                    <div className="fleft width6">
                      {illCafDataupdated[8698].value === 'ISDN' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      ISDN
                    </div>
                    <div className="fleft wid36">
                      {illCafDataupdated[8698].value === 'Others' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Others
                      <input type="text" name="" className="form_col lm" />
                    </div>
                  </div>
                  <div className="check over_hid pd_t3 font9">
                    <div className="fleft width110px pad_t3"> Backup LM </div>
                    <div className="fleft width6">
                      {illCafDataupdated[8699].value === 'TDM' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      TDM
                    </div>
                    <div className="fleft width6">
                      {illCafDataupdated[8699].value === 'CEN' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      CEN
                    </div>
                    <div className="fleft width6">
                      {illCafDataupdated[8699].value === 'EOSDH' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      EOSDH
                    </div>
                    <div className="fleft width6">
                      {illCafDataupdated[8699].value === 'BWA' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BWA
                    </div>
                    <div className="fleft width6">
                      {illCafDataupdated[8699].value === 'Radio P2P' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Radio P2P
                    </div>
                    <div className="fleft width6">
                      {illCafDataupdated[8699].value === 'ISDN' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      ISDN
                    </div>
                    <div className="fleft wid36">
                      {illCafDataupdated[8699].value === 'Others' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Others
                      <input type="text" name="" className="form_col lm" />
                    </div>
                  </div>
                  <div className="check over_hid pd_t3 font9">
                    <div className="fleft width110px pad_t3">
                      {' '}
                      Customer end equipment*
                    </div>
                    <div className="fleft width12">
                      {illCafDataupdated[8700].value === 'Router/Switch' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Router/Switch
                    </div>

                    <div className="fleft width6">
                      {illCafDataupdated[8700].value === 'Modem' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Modem
                    </div>

                    <div className="fleft width6">
                      {illCafDataupdated[8700].value === 'Convertor' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Convertor
                    </div>

                    <div className="fleft width6">
                      {illCafDataupdated[8700].value === 'Others' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Others
                    </div>

                    <div className="fleft width15">
                      If Router/Switch - Qty
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={illCafDataupdated[8701].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15">
                      If Modem - Qty
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={illCafDataupdated[8702].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15">
                      If Convertor - Qty
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={illCafDataupdated[8703].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="margin0">
                    # Detailed BOM / BOQ to be attached separately
                  </p>
                  <div className="check over_hid pd_t5 font9">
                    <div className="fleft width110px pad_t3">
                      Customer end Interface
                    </div>
                    <div className="fleft width6">
                      {illCafDataupdated[8705].value === 'V.35' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      V.35
                    </div>
                    <div className="fleft width6">
                      {illCafDataupdated[8705].value === 'G.703' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      G.703
                    </div>
                    <div className="fleft width15">
                      {illCafDataupdated[8705].value ===
                      'Ethernet Electrical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Electrical
                    </div>
                    <div className="fleft width6">
                      {illCafDataupdated[8705].value === 'BNC' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BNC
                    </div>
                    <div className="fleft width15">
                      {illCafDataupdated[8705].value === 'Ethernet Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Optical
                    </div>
                    <div className="fleft width6">
                      {illCafDataupdated[8705].value === 'Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Optical
                    </div>
                  </div>
                  <div className="check over_hid pd_t5">
                    <div className="fleft width110px pad_t3">Dual Homing</div>
                    <div className="fleft width6">
                      {illCafDataupdated[8706].value === 'Yes' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Yes
                    </div>
                    <div className="fleft width6">
                      {illCafDataupdated[8706].value === 'No' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      No
                    </div>
                    <div className="fleft width6 pd_t5"> if yes, then</div>
                    <div className="fleft width15 pd_t5">
                      {' '}
                      Service Provider Pop1
                    </div>
                    <div className="fleft width6">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={illCafDataupdated[8707].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width24 pd_t5 textcenter">
                      {' '}
                      Servce Provider PoP2, if dual homing
                    </div>
                    <div className="fleft width13">
                      <input
                        type="text"
                        name=""
                        className="form_col width90"
                        value={illCafDataupdated[8708].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pd_t5">
                    <div className="fleft width25 pd_t5">
                      {' '}
                      Primary Last Mile Service Provider{' '}
                    </div>
                    <div className="fleft width25">
                      <input
                        type="text"
                        name=""
                        className="form_col width90"
                        value={illCafDataupdated[8709].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width25 pd_t5">
                      {' '}
                      Secondary Last Mile Service Provider{' '}
                    </div>
                    <div className="fleft width25">
                      <input
                        type="text"
                        name=""
                        className="form_col width90"
                        value={illCafDataupdated[8710].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pd_t5">
                    <div className="fleft width25 pd_t5">
                      {' '}
                      Primary CIR Bandwidth in Mbps/Kbps{' '}
                    </div>
                    <div className="fleft width25">
                      <input
                        type="text"
                        name=""
                        className="form_col width90"
                        value={illCafDataupdated[8711].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width25 pd_t5 font9">
                      {' '}
                      Secondary CIR Bandwidth in Mbps/Kbps{' '}
                    </div>
                    <div className="fleft width25">
                      <input
                        type="text"
                        name=""
                        className="form_col width90"
                        value={illCafDataupdated[8712].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pd_t5">
                    <div className="fleft width25 pd_t5">
                      {' '}
                      Parallel Bandwidth Mbps/Kbps{' '}
                    </div>
                    <div className="fleft width25">
                      <input
                        type="text"
                        name=""
                        className="form_col width90"
                        value={illCafDataupdated[8713].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft pd_t5 font9">
                      {' '}
                      To be filled only if the secound link used on load sharing
                      basis{' '}
                    </div>
                  </div>
                  <div className="check over_hid pd_t5 pad_red">
                    <div className="fleft width25 pd_t5">
                      {' '}
                      Routing Protocol{' '}
                    </div>
                    <div className="fleft width16">
                      {illCafDataupdated[8714].value === 'Static' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Static
                    </div>
                    <div className="fleft width16">
                      {illCafDataupdated[8714].value === 'BGP' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BGP
                    </div>
                  </div>
                  <div className="over_hid pd_t5">
                    <div className="fleft width25 pd_t5"> If BGP </div>
                    <div className="fleft">
                      As Number{' '}
                      <input
                        type="text"
                        name=""
                        className="form_col n_input width170px"
                        value={illCafDataupdated[8715].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pd_t5">
                    <div className="fleft width25 pd_t5"> &nbsp; </div>
                    <div className="fleft width16">
                      {illCafDataupdated[8716].value === 'Partial Routes' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Partial Routes
                    </div>
                    <div className="fleft width16">
                      {illCafDataupdated[8716].value === 'Full Routes' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Full Routes
                    </div>
                  </div>
                  <div className="check over_hid pd_t5">
                    <div className="fleft width25 pd_t5"> &nbsp; </div>
                    <div className="fleft width20">Loop Back IP Address</div>
                    <div className="fleft width30">
                      <table
                        width="100%"
                        border="0"
                        cellSpacing="0"
                        cellPadding="0"
                        className="iptable"
                      >
                        <tbody>
                          <tr>
                            <td>{illCafDataupdated[8717].value}&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="check over_hid pd_t5">
                    <div className="fleft width25 pd_t5"> If Static </div>
                    <div className="fleft width16 pad_t3">
                      IP Address Provided By
                    </div>
                    <div className="fleft width16">
                      {illCafDataupdated[8719].value === 'Vodafone Idea' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Vodafone Idea
                    </div>
                    <div className="fleft width16">
                      {illCafDataupdated[8719].value === 'Customer' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Customer
                    </div>
                  </div>
                  <p className="margin0">
                    In case of customer provided IP address or in case of BGP,
                    specify subnets/prefixes to advertise
                  </p>
                  <div className="signate float_img">
                    {firstCaps !== '' ? (
                      <div style={{ padding: '10px' }} className={Signcss}>
                        {firstCaps + '  .  '}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ''
                    )}
                    {imageData !== null && imageData !== '' ? (
                      <div style={{ padding: '10px' }} className="signate_img">
                        <img
                          src={
                            imageData !== null
                              ? 'data:' +
                                imageType +
                                ';base64,' +
                                encode(imageData)
                              : ''
                          }
                          alt={'Company Seal'}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="check over_hid pad_t10">
                    <div className="fleft width40">
                      <table
                        width="100%"
                        border="0"
                        cellSpacing="0"
                        cellPadding="0"
                        className="iptable"
                      >
                        <tbody>
                          <tr>
                            <td>{illCafDataupdated[8720].value}</td>
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2 font8">
              <p className="customfom">Product Form - Internet Leased Line</p>
              <div className="over_hid">
                <div className="fleft">
                  {' '}
                  In case of VIL IP addresses. please specify additional number
                  of IP address required{' '}
                </div>
                <div className="fleft pad_l10">
                  <input
                    type="text"
                    name=""
                    className="form_col n_input width170px"
                    value={illCafDataupdated[8721].value}
                    readOnly
                  />
                </div>
              </div>
              <div className="check over_hid">
                <div className="">
                  {' '}
                  (Over and above deafult IP address offered)
                </div>
              </div>

              <div className="check over_hid">
                <div className="fleft width170px">
                  Authoratitive DNS Service required
                </div>
                <div className="fleft width12">
                  {illCafDataupdated[8722].value === 'Yes' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Yes
                </div>
                <div className="fleft width12">
                  {illCafDataupdated[8722].value === 'No' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  No
                </div>
              </div>
              <div className="check over_hid pad0">
                <div className="fleft width170px"> If Yes </div>
              </div>

              <div className="domainame">
                <table width="30%" border="0" cellSpacing="0" cellPadding="0">
                  <tbody>
                    <tr>
                      <td>Domain Name</td>
                      <td>
                        Record <br />
                        Type
                      </td>
                    </tr>
                    <tr>
                      <td>{illCafDataupdated[8723].value}</td>
                      <td>{illCafDataupdated[8724].value}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="check over_hid">
                <div className="fleft width170px pad_t3">
                  Self Care Service Varient{' '}
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8725].value === 'Silver Plus' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Silver Plus
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8725].value === 'Gold' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Gold{' '}
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8725].value === 'Gold Plus' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Gold Plus
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8725].value === 'None' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  None
                </div>
              </div>

              <div className="check over_hid">
                <div className="fleft width170px pad_t3">
                  Managed Service Varient{' '}
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8726].value === 'Silver' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Silver
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8726].value === 'Gold' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Gold{' '}
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8726].value === 'Platinum' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Platinum
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8726].value === 'None' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  None
                </div>
              </div>

              <div className="check over_hid">
                <div className="fleft width170px pad_t3"> CPF Managed by </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8727].value === 'Vodafone Idea' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Vodafone Idea
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8727].value === 'Customer' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Customer
                </div>
              </div>
              <div className="check over_hid">
                <div className="fleft width170px pad_t3">
                  {' '}
                  CPF Maintaind by{' '}
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8728].value === 'Vodafone Idea' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Vodafone Idea
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8728].value === 'Customer' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Customer
                </div>
              </div>
              <div className="check over_hid">
                <div className="fleft width170px pad_t3">
                  {' '}
                  Managed Security Varients{' '}
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8729].value === 'Standard' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Standard
                </div>
                <div className="fleft width_7">
                  {illCafDataupdated[8729].value === 'Silver' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Silver
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8729].value === 'Silver Plus' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Silver Plus
                </div>
                <div className="fleft width_7">
                  {illCafDataupdated[8729].value === 'Gold' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Gold
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8729].value === 'Gold Plus' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Gold Plus
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8729].value === 'Platinum' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Platinum
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8729].value === 'Platinum Plus' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Platinum Plus
                </div>
              </div>

              <div className="check over_hid">
                <div className="fleft width170px pad_t3">
                  {' '}
                  SMTP Realy Service Rquired{' '}
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8730].value === 'Yes' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Yes
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8730].value === 'No' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  No
                </div>
              </div>

              <div className="check over_hid">
                <div className="fleft width170px pad_t3">Encapsulation </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8731].value === 'IP' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  IP
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8731].value === 'Ethernet' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Ethernet
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8731].value === 'HDLC/ PPP' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  HDCL/PPP
                </div>
              </div>

              <div className="check over_hid">
                <div className="fleft width170px pad_t3">
                  Managed Wifi Service Required{' '}
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8732].value === 'Yes' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Yes
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8732].value === 'No' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  No
                </div>
              </div>

              <div className="check over_hid">
                <div className="fleft width170px pad_t3">
                  Managed Proxy Service Required{' '}
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8733].value === 'Yes' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Yes
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8733].value === 'No' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  No
                </div>
              </div>

              <div className="check over_hid">
                <div className="fleft width170px pad_t3">SLA Type</div>
                <div className="fleft width_12">
                  {illCafDataupdated[8734].value === 'Standard' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Standard
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8734].value === 'Customized' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Customized
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8734].value === 'NONE' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  NONE
                </div>
              </div>
              <div className="check over_hid">
                <div className="fleft width170px pad_t3">
                  {' '}
                  If SLA Type Customized
                </div>
                <div className="fleft width15">
                  Service Availability (%)
                  <br />
                  <input
                    className="form_col width75"
                    type="text"
                    name=""
                    value={illCafDataupdated[8736].value}
                    readOnly
                  />
                </div>
                <div className="fleft width15">
                  MTTR (Hours)
                  <br />
                  <input
                    className="form_col width75"
                    type="text"
                    name=""
                    value={illCafDataupdated[8737].value}
                    readOnly
                  />
                </div>
                <div className="fleft width15">
                  Latency (ms)
                  <br />
                  <input
                    className="form_col width75"
                    type="text"
                    name=""
                    value={illCafDataupdated[8738].value}
                    readOnly
                  />
                </div>
                <div className="fleft width15">
                  Packet Loss (%)
                  <br />
                  <input
                    className="form_col width75"
                    type="text"
                    name=""
                    value={illCafDataupdated[8739].value}
                    readOnly
                  />
                </div>
                <div className="fleft width15">
                  Jitter (ms)
                  <br />
                  <input
                    className="form_col width75"
                    type="text"
                    name=""
                    value={illCafDataupdated[8740].value}
                    readOnly
                  />
                </div>
              </div>

              <div className="check over_hid">
                <div className="fleft width170px pad_t3">
                  DDOS Service Required
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8741].value === 'Yes' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Yes
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8741].value === 'No' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  No
                </div>
              </div>

              <div className="check over_hid">
                <div className="fleft width170px pad_t3">If Yes</div>
              </div>

              <div className="over_hid">
                <div className="fleft width170px pad_t3">
                  DOoS Migation Upto
                </div>
                <div className="fleft width_20">
                  <input
                    className="form_col n_input wid120p"
                    type="text"
                    name=""
                    value={illCafDataupdated[8743].value}
                    readOnly
                  />
                  Mbps
                </div>
              </div>
              <div className="check over_hid">
                <div className="fleft width170px pad_t3">DoOS Flavour</div>
                <div className="fleft width_12">
                  {illCafDataupdated[8744].value === 'Gold' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Gold
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[8744].value === 'Silver' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Silver
                </div>
              </div>

              <div className="check over_hid">
                <div className="fleft width170px pad_t3">
                  Cloud Firewall Required
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[310200].value === 'Yes' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Yes
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[310200].value === 'No' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  No
                </div>
              </div>
              <div className="check over_hid">
                <div className="fleft width170px pad_t3">If Yes</div>
              </div>
              <div className="check over_hid">
                <div className="fleft width170px pad_t3">Variant</div>
                <div className="fleft width_12">
                  {illCafDataupdated[310201].value === 'Shared' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Shared
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[310201].value === 'Dedicated' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Dedicated
                </div>
              </div>
              <div className="check over_hid">
                <div className="fleft width170px pad_t3">If Dedicated</div>
              </div>
              <div className="check over_hid">
                <div className="fleft width170px pad_t3">Flavour</div>
                <div className="fleft width_12">
                  {illCafDataupdated[310202].value === 'Essential' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Essential
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[310202].value === 'Standard' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Standard
                </div>
                <div className="fleft width_12">
                  {illCafDataupdated[310202].value === 'Premium' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Premium
                </div>
              </div>

              <div className="">
                <p className="ligthbg">CHARGE DESCRIPTION</p>
              </div>
              <div className="char">
                <div className="check over_hid">
                  <div className="fleft width170px pad_t3">
                    {' '}
                    <strong>Specify Currency</strong>
                  </div>
                  <div className="fleft width7">
                    {illCafDataupdated[8746].value === 'INR' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    INR{' '}
                  </div>
                  <div className="fleft width7">
                    {illCafDataupdated[8746].value === 'US$' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    US${' '}
                  </div>
                </div>

                <div className="check over_hid">
                  <div className="fleft width170px pad_t3">&nbsp; </div>
                  <div className="fleft width7">&nbsp; </div>
                  <div className="fleft width7">&nbsp; </div>
                  <div className="fleft width15 textcenter"> One Time (A) </div>
                  <div className="fleft width15 textcenter">
                    {' '}
                    Annual Recurring (B){' '}
                  </div>
                  <div className="fleft width15 textcenter">
                    {' '}
                    Security Deposit (C){' '}
                  </div>
                  <div className="fleft width15 textcenter">
                    {' '}
                    Grand Total (A+B+C){' '}
                  </div>
                </div>

                <div className="check over_hid">
                  <div className="fleft width170px pad_t3"> Service Rental</div>
                  <div className="fleft width7">&nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8747].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8748].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8749].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8750].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    {' '}
                    IP Address Charges
                  </div>
                  <div className="fleft width7">&nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8751].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8752].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter"> &nbsp; </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8753].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3"> SLA Charges</div>
                  <div className="fleft width7">&nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8754].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8755].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter"> &nbsp; </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8756].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    {' '}
                    Self Care Portal Service Charges
                  </div>
                  <div className="fleft width7">&nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8757].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8758].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter"> &nbsp; </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8759].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3"> BoD Charges</div>
                  <div className="fleft width7">&nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8760].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8761].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter fnt6">
                    {' '}
                    Rate per Mbps/Day
                    <input
                      className="form_col width28p"
                      type="text"
                      name=""
                      value={illCafDataupdated[8762].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8763].value}
                      readOnly
                    />
                  </div>
                </div>

                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    {' '}
                    ToD Subscription Charges
                  </div>
                  <div className="fleft width7">&nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8764].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8765].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter fnt6">
                    {' '}
                    Rate per Mbps/Hour
                    <input
                      className="form_col width28p"
                      type="text"
                      name=""
                      value={illCafDataupdated[8766].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8767].value}
                      readOnly
                    />
                  </div>
                </div>

                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    Nith Percentage Subscription Charges
                  </div>
                  <div className="fleft width7">&nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8768].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8769].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter fnt6">
                    {' '}
                    Rate per Mbps/Month
                    <input
                      className="form_col width28p"
                      type="text"
                      name=""
                      value={illCafDataupdated[8770].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8771].value}
                      readOnly
                    />
                  </div>
                </div>

                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    Managed Proxy Service Charges
                  </div>
                  <div className="fleft width7">&nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8772].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8773].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter fnt6"> &nbsp; </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8774].value}
                      readOnly
                    />
                  </div>
                </div>

                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    DDos Service Charges
                  </div>
                  <div className="fleft width7">&nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8775].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8776].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter fnt6"> &nbsp; </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8777].value}
                      readOnly
                    />
                  </div>
                </div>

                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    SMTP Relay Service Charges
                  </div>
                  <div className="fleft width7">&nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8778].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8779].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter fnt6"> &nbsp; </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8780].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    Managed Service Charges
                  </div>
                  <div className="fleft width7">&nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8781].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8782].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter fnt6"> &nbsp; </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8783].value}
                      readOnly
                    />
                  </div>
                </div>

                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    Managed CPE Charges
                  </div>
                  <div className="fleft width7">&nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8784].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8785].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter fnt6"> &nbsp; </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8786].value}
                      readOnly
                    />
                  </div>
                </div>

                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    Managed Security Charges
                  </div>
                  <div className="fleft width7">&nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8787].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8788].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter fnt6"> &nbsp; </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8789].value}
                      readOnly
                    />
                  </div>
                </div>

                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    Cloud Firewall Rentals
                  </div>
                  <div className="fleft width7">&nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[310203].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[310204].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[310205].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[310239].value}
                      readOnly
                    />
                  </div>
                </div>

                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3"> CPE Rentals</div>
                  <div className="fleft width7">&nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8790].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8791].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8792].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8793].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3"> </div>
                  <div className="fleft width7 pad_t5">CPE 1 </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8794].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8795].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8796].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8797].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className={Overlapcss}>
                    {firstCaps !== '' ? (
                      <div style={{ padding: '10px' }} className={Signcss}>
                        {firstCaps + '  .  '}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ''
                    )}
                    {imageData !== null && imageData !== '' ? (
                      <div style={{ padding: '10px' }} className="signate_img">
                        <img
                          src={
                            imageData !== null
                              ? 'data:' +
                                imageType +
                                ';base64,' +
                                encode(imageData)
                              : ''
                          }
                          alt={'Company Seal'}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="fleft width170px pad_t3"> </div>
                  <div className="fleft width7 pad_t5">CPE 2 </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8798].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8799].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8800].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8801].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3"> </div>
                  <div className="fleft width7 pad_t5">CPE 3 </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8802].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8803].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8804].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8805].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    Miscellaneous Charges - 1{' '}
                  </div>
                  <div className="fleft width7 pad_t5"> &nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8806].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8807].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter fnt6"> &nbsp; </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8808].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    Miscellaneous Charges - 2{' '}
                  </div>
                  <div className="fleft width7 pad_t5"> &nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8809].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8810].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter"> &nbsp; </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8811].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    {' '}
                    Installation Charges{' '}
                  </div>
                  <div className="fleft width7 pad_t5"> &nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8812].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter"> &nbsp; </div>
                  <div className="fleft width15 textcenter"> &nbsp; </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={illCafDataupdated[8813].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3"> Total Charges </div>
                  <div className="fleft width7 pad_t5"> &nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75 input_red"
                      type="text"
                      name=""
                      value={illCafDataupdated[8814].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75 input_red"
                      type="text"
                      name=""
                      value={illCafDataupdated[8815].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter"> &nbsp; </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75 input_red"
                      type="text"
                      name=""
                      value={illCafDataupdated[8816].value}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="check over_hid pad_t5">
                <div className="fleft width170px pad_t3">
                  <strong> Total Order Value</strong>{' '}
                </div>
                <div className="fleft width7 pad_t5"> &nbsp; </div>
                <div className="fleft width7"> &nbsp;</div>
                <div className="fleft width45 mar_l7">
                  <input
                    className="form_col width90"
                    type="text"
                    name=""
                    value={illCafDataupdated[8817].value}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2 font8">
              <div className="pad_t10">
                <p className="customfom">Product Form - Internet Leased Line</p>
              </div>
              <div className="pad_t5">
                <p className="ligthbg">COMMENTS</p>
              </div>
              <p>
                <textarea
                  className="textarea min50"
                  placeholder="Comments"
                  value={illCafDataupdated[8818].value}
                  readOnly
                ></textarea>
              </p>

              <div className="over_hid">
                <div className="fleft width70 pad_t10">
                  <div className="">
                    <strong>Declaration</strong>
                  </div>
                  <div className="fon8">
                    {' '}
                    I/We confirm having recived read and undoorstud the Product
                    & Conditions (provided overleaf) and the General Terms &{' '}
                    <br />
                    Conditions. I/We further confirm that the tarif plan
                    selected and applicable rates from part of this aggrement
                    (as difined herein)
                    <br />
                    and I/We agree to abade by the application law in force and
                    also any statutory amendments. or new legistations as may be
                    enected
                    <br />
                    from time to tim, in so fare as they relate to the services.
                    I/We hereby declare and confirm that the informaton provided
                    by
                    <br />
                    us is true and correct in all recepts and I/We hereby
                    undertake to b bound by the same.
                  </div>

                  <p className="marb5">
                    Applicant's* / Authorised Signatory's Name **
                  </p>
                  <p className="margin0">
                    <input
                      className="form_col w_85 inputext"
                      type="text"
                      name=""
                      value={illCafDataupdated[8821].value}
                      readOnly
                    />
                  </p>
                  <div className="over_hid pad_t30">
                    <div className="fleft width40">
                      Designation
                      <input
                        className="form_col width90 inputext design"
                        type="text"
                        name=""
                        value={illCafDataupdated[8822].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width30">
                      Place
                      <input
                        className="form_col width90 inputext design"
                        type="text"
                        name=""
                        value={illCafDataupdated[8823].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width30">
                      Date
                      <input
                        className="form_col width90 inputext design"
                        type="text"
                        name=""
                        value={illCafDataupdated[8824].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="flright width30">
                  <div style={{ padding: '5px' }} className="signate">
                    {firstCaps !== '' ? (
                      <div className={Signcss}>
                        {firstCaps + '  .  '}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ''
                    )}
                    <div>
                      Signature of the Customer / Authorised signatory with in
                      this box only (In case of institutinal, please affix
                      office/company seal){' '}
                    </div>
                  </div>
                </div>
              </div>
              <div className="over_hid pad_t30">
                <div className="fleft width65 pad_t10">
                  <p className="marb5">Account Managers Name</p>
                  <p className="margin0">
                    <input
                      className="form_col widthfull inputext"
                      type="text"
                      name=""
                      value={illCafDataupdated[8826].value}
                      readOnly
                    />
                  </p>
                  <p className="marb5">Account Manager Circle Office </p>
                  <p className="margin0">
                    <input
                      className="form_col width50 inputext_brd_list"
                      type="text"
                      name=""
                      value={illCafDataupdated[8827].value}
                      readOnly
                    />
                  </p>
                  <p className="marb5">Channel Partner Name</p>
                  <p className="margin0">
                    <input
                      className="form_col widthfull inputext"
                      type="text"
                      name=""
                      value={illCafDataupdated[8829].value}
                      readOnly
                    />
                  </p>
                  <p className="marb5">Channel Partner Code</p>
                  <p className="margin0">
                    <input
                      className="form_col width50 inputext_brd_list"
                      type="text"
                      name=""
                      value={illCafDataupdated[8830].value}
                      readOnly
                    />
                  </p>
                  <br />
                  <br />
                  <p>
                    <strong>Vodafone Idea Limited</strong> ( Formerly Idea
                    Cellular Limited )<br />
                    An Adity Birla Group and Vodafone partneship
                    <br />
                    MergerCo CIN No: L32100GJ1996PLC030976
                    <br />
                    <strong>Registred Office:</strong> Suman Tower, Plot no. 18,
                    Sector 11, Gandhinagar - 382 011, Gujrat, T: +91 79 66714000
                    | F: +91 79 23232251
                    <br />
                    <strong>Circle Office Address:</strong>{' '}
                  </p>
                </div>
                <div className="flright width30">
                  <div
                    style={{ padding: '5px', height: '120px' }}
                    className="signate"
                  >
                    {acctMngSignImageData !== null &&
                    acctMngSignImageData !== '' ? (
                      <img
                        height="90px"
                        width="100px"
                        src={
                          acctMngSignImageData !== null
                            ? 'data:' +
                              acctMngSignImageType +
                              ';base64,' +
                              encode(acctMngSignImageData)
                            : ''
                        }
                        alt={'Signature of Account Manager'}
                      />
                    ) : (
                      ''
                    )}
                    <div>Signature of the Account Manager </div>
                  </div>
                </div>
              </div>
              <div className="signate float_img">
                {firstCaps !== '' ? (
                  <div style={{ padding: '10px' }} className={Signcss}>
                    {firstCaps + '  .  '}
                    <span className="under_line">{secondBold}</span>
                  </div>
                ) : (
                  ''
                )}
                {imageData !== null && imageData !== '' ? (
                  <div
                    style={{ padding: '10px', marginLeft: '-20px' }}
                    className="signate_img"
                  >
                    <img
                      src={
                        imageData !== null
                          ? 'data:' + imageType + ';base64,' + encode(imageData)
                          : ''
                      }
                      alt={'Company Seal'}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2 font8">
              <div className="">
                <div className="pad_b5">
                  <p className="customfom">
                    Product Form - Internet Leased Line
                  </p>
                </div>
                <p className="ligthbg font8">
                  ILL Service access under ISP License Terms & Conditions
                </p>
                <p className="font10">
                  <strong>
                    This &quot;Customer Application Form&quot; (CAF) shall mean
                    together (i) the customer information form; and (ii) the
                    following conditions (&quot;Regulatory Terms&quot;), which
                    are required to be provided/completed with effect from /or
                    prior to activation of Internet Leased Line Service provided
                    by Vodafone Idea Limited under its ISP- All India (Cat A)
                    License and these &#39;Regulatory Terms&#39; shall continue
                    during the term Of service.
                  </strong>{' '}
                </p>
                <div className="regtext">
                  Regulatory terms governing mpls vpn leased line services
                </div>
                <div className="over_hid">
                  <div className="fleft width49">
                    <ul className="list">
                      <li>
                        1. The Customer undertakes to fully comply with all the
                        applicable laws and regulations inforce from time to
                        time ( Including but not limited to Indian telegraph Act
                        1 885, Indian telegraph rules 1951 , Information
                        Technology Act 2000 and amendments thereof, TRAAct 1997
                        ) governing the use Telecom services provided under this
                        CAF.{' '}
                      </li>
                      <li>
                        2. The Customer shall strictly ensure the bonafide use
                        Of the ILL/ or Wi-Fi services providedunder this CAF.{' '}
                      </li>
                      <li>
                        3. The Customer undertakes to provide the Certified copy
                        of their OSP Registration or ISP License or any other
                        Telecom License in case they are holding the same or
                        become holder of the same at any point of time, while
                        availing the services under this CAF.{' '}
                      </li>
                      <li>
                        4. The Customer agrees and undertakes that the services
                        provided to him shall not be used for any of the
                        activities mentioned hereunder • Voice Telephony (PSTN
                        /ISDN/SIP Trunking/PLMN). • Connectivity of the ILL with
                        any PLMN/PSTN Network • Reselling of the ILL (Not
                        Applicable in case of the Customers holding SP license)
                        • Providing of any Foreign VOIP Minutes as per DOT
                        letter no.18-1/2009-CS dated 25/02/2009{' '}
                      </li>
                      <li>
                        5. As per prevailing laws, the Customer shall not employ
                        bulk encryption and is permitted to use ncryption up to
                        40bit key length in the RSA algorithms or its equivalent
                        in other algorithms without having to obtain permission.
                        However, if the encryption requirement is higher than
                        this limit, the Customer shall obtain the permission Of
                        the concerned Telecom Authority and shall deposit the
                        encryption key, split into two parts, with such Telecom
                        Authority.{' '}
                      </li>
                      <li>
                        6. In case the customer has deployed Network Address
                        Translation (NAT) with respect to theInternet services
                        availed by him under this CAF, then such customer shall
                        comply with the instructions issued by DOT vide No
                        820-01 /98-LR/VoL(lX) Ptl dated 01.10.2013,. for
                        recording & maintenance of NAT SYS LOG parameters for a
                        period of at least one year The Customer shall further
                        produce such logs on demand by the competent authorities{' '}
                      </li>
                      <li>
                        7. VIL may block the Internet Services, Access to the
                        specific Websites/ URL's or IP Addresses as per the
                        directions received from the Telecom Authority or any
                        other competent authority from time to time.
                      </li>
                      <li>
                        8. The Customer must ensure that Service provided, is
                        not used for any unlawful purposes using the Services as
                        End User. The Customer shall ensure that objectionable,
                        obscene,unauthorized or any other content, messages or
                        communications infringing copyright,ntellectual property
                        right and international & domestic cyber laws, in any
                        form or inconsistent with the laws of India, are not
                        carried in their network by them or any other authorized
                        person using their network or during the provisioning of
                        Services. The Customer must take all necessary measures
                        to prevent such use. The use of the Service for
                        anti-national activities shall be construed as an
                        offence punishable under the Indian Penal Code or other
                        applicable law...
                      </li>
                      <li>
                        9. Customer shall allow VIL employees or authorized
                        personnel, enter at all times into the premises where
                        the Services are provided for periodical inspection,
                        installing, maintaining, replacing and removing
                        equipment hardware and/or software prior to, during and
                        after the provision of the services.{' '}
                      </li>
                    </ul>
                  </div>
                  <div className="fright width49">
                    <ul className="list">
                      <li>
                        10. VIL warrants that the Services shall be of the
                        acceptable grade, consistent with the established and
                        generally accepted standards. However, the quality,
                        functionality,availability or reliability of the same
                        may be affected from time to time.{' '}
                      </li>
                      <li>
                        11. VIL shall be entitled to collect/recover financial
                        penalty, if any, as may be levied by Government on it on
                        account of any breach of regulatory terms in this CAF
                        and shall also be entitled, without any liability, to
                        refuse, limit, suspend, vary, disconnect and or
                        interrupt the Services, in whole or in part, at any
                        time, for any reason and/or due to various factors
                        including but not limited to: • Government's rules,
                        regulations, orders, directions, etc. • Combat potential
                        fraud, sabotage, etc. • Force-Majeure circumstances.
                      </li>
                      <li>
                        12. In the event Customer installs any Wi-Fi network of
                        its own, the Customer shall ensure the compliance with
                        the DOT Guidelines No 820, 1 / 2008-DS Pt —II dated 01 •
                        10-2009 issued for Centralized Authentication. Customer
                        shall also ensure use the secured Wi-Fi network
                        connection to avoid any misuse.{' '}
                      </li>
                      <li>
                        13. Customer shall ensure that any network equipment
                        (not provided by Services Provider) which it uses in
                        relation to the Services meet the relevant International
                        Telecommunication Union(ITU)/Telecommunication
                        Engineering Centre (TEC) standards;{' '}
                      </li>
                      <li>
                        14. VIL may change, amend or revise the above regulatory
                        terms and conditions at any time as and when necessary
                        in order to comply with any statutory, legal or
                        regulatory requirements and the Customer agrees to abide
                        by such modified terms and conditions. Such changes,
                        amendments or revisions shall be deemed effective upon
                        posting an updated and duly dated regulatory compliance
                        to the Customer via email, fax, post orthrough any other
                        medium opted by VIL{' '}
                      </li>
                      <li>
                        15. Customer has read, understands and agrees to VIL
                        Privacy Policy which is available at
                        https://mw,/.vodafone.in/privacy-policy.{' '}
                      </li>
                      <li>
                        16. The Regulatory Terms under this CAF binds the
                        Customer, their executors, administrators, successors
                        and permitted assignees to the terms & conditions of the
                        CAF, along with the Terms & conditions of MSA & SLA ( if
                        any).{' '}
                      </li>
                      <li>
                        17. GST & SEZ STATUS: The customer will be solely
                        responsible for providing the correct GST number and
                        'ISEZ' related information against the "Place of Supply"
                        as per the GST law. S/he waives off all the rights to
                        dispute or withhold any payments in case of incorrect
                        GST Numbers or incorrect SEZ certificates/ status being
                        provided, which don't match the detailsavailable on the
                        Government GSTN portal. The customer acknowledges that
                        s/he will not be able to claim the GST tax credit under
                        such circumstances and shall pay 100% invoiced amount,
                        including taxes{' '}
                      </li>
                    </ul>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <div
                  className="signate float_img"
                  style={{ margin: '-65px 0 0' }}
                >
                  {firstCaps !== '' ? (
                    <div style={{ padding: '10px' }} className={Signcss}>
                      {firstCaps + '  .  '}
                      <span className="under_line">{secondBold}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {imageData !== null && imageData !== '' ? (
                    <div style={{ padding: '10px' }} className="signate_img">
                      <img
                        src={
                          imageData !== null
                            ? 'data:' +
                              imageType +
                              ';base64,' +
                              encode(imageData)
                            : ''
                        }
                        alt={'Company Seal'}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2 font8">
              <div className="pad_b5">
                <p className="customfom">Product Form - Internet Leased Line</p>
                <p className="ligthbg font8">Terms & Conditions</p>
                <p className="font8">
                  <strong>Customer Responsibility</strong>
                </p>
                <p className="font8">
                  Customer would be required to provide following for site
                  readlness
                </p>
                <div className="check over_hid pad_t10">
                  <div className="fleft width40 pad_7t">
                    Lockable conditioned space for housing teleco equipments
                  </div>
                  <div className="fleft width30">
                    <input
                      className="form_col wid120p"
                      type="text"
                      name=""
                      value={illCafDataupdated[8834].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t10">
                  <div className="fleft width40 pad_7t">Power</div>
                  <div className="fleft width30">
                    <input
                      className="form_col wid120p"
                      type="text"
                      name=""
                      value={illCafDataupdated[8835].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t10">
                  <div className="fleft width40 pad_7t">Earthing</div>
                  <div className="fleft width30">
                    <input
                      className="form_col wid120p"
                      type="text"
                      name=""
                      value={illCafDataupdated[8836].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="font8">
                  Customer would be required to grant following permission to
                  VIL & its carrier partner
                </p>
                <div className="check over_hid pad_t10">
                  <div className="fleft width40 pad_7t">
                    Right of way permission
                  </div>
                  <div className="fleft width30">
                    <input
                      className="form_col wid120p"
                      type="text"
                      name=""
                      value={illCafDataupdated[8838].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t10">
                  <div className="fleft width40 pad_7t">
                    Roof top space for poie / tower installation
                  </div>
                  <div className="fleft width30">
                    <input
                      className="form_col wid120p"
                      type="text"
                      name=""
                      value={illCafDataupdated[8839].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t10">
                  <div className="fleft width40 pad_7t">
                    Ground space for poie / tower installation
                  </div>
                  <div className="fleft width30">
                    <input
                      className="form_col wid120p"
                      type="text"
                      name=""
                      value={illCafDataupdated[8840].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t10">
                  <div className="fleft width40 pad_7t">
                    Site access to employees / authorized reprasentatives of{' '}
                    <br />
                    VIL/ its affialties in the excution of their duty for <br />
                    the purpose of installation and / or configuration of
                    euipment
                  </div>
                  <div className="fleft width30">
                    <input
                      className="form_col wid120p"
                      type="text"
                      name=""
                      value={illCafDataupdated[8841].value}
                      readOnly
                    />
                  </div>
                </div>
                <p>
                  <strong>Deemed Acceptance</strong>
                  <br />
                  Link Acceptance: Customers are expected to provide acceptance
                  of the link(s) in 5 days post testing after which VIL will
                  start billing the customer for the link(s). In absence of any
                  shortcomings on the link(s) performance or acceptance
                  communication from the customer within 5 days, VIL will deem
                  the links as accepted by the customer.
                </p>
                <p> Customer Declartion and Signatures follow </p>
                <div className="over_hid">
                  <div className="fleft width70 pad_t10">
                    <div className="">
                      <strong>Customer declearation</strong>
                    </div>
                    <div className="">
                      {' '}
                      I/We confirm having recived read and undoorstud the
                      Product &amp; Conditions (provided overleaf) and the
                      General Terms &amp; Conditions. I/We further confirm that
                      the tarif plan selected and applicable rates from part of
                      this aggrement (as difined herein) and I/We agree to abade
                      by the application law in force and also any statutory
                      amendments. or new legistations as may be enected from
                      time to tim, in so fare as they relate to the services.
                      I/We hereby declare and confirm that the informaton
                      provided by us is true and correct in all recepts and I/We
                      hereby undertake to b bound by the same.I/We confirm
                      having recived read and undoorstud the Product &amp;
                      Conditions (provided overleaf) and the General Terms &amp;
                      Conditions. I/We further confirm that the tarif plan
                      selected and applicable rates from part of this aggrement
                      (as difined herein) and I/We agree to abade by the
                      application law in force and also any statutory
                      amendments.
                    </div>
                  </div>
                  <div className="flright width30">
                    <div
                      style={{ padding: '5px', height: '120px' }}
                      className="signate"
                    >
                      {imageData !== null && imageData !== '' ? (
                        <img
                          height="90px"
                          width="100px"
                          src={
                            imageData !== null
                              ? 'data:' +
                                imageType +
                                ';base64,' +
                                encode(imageData)
                              : ''
                          }
                          alt={'Company Seal'}
                        />
                      ) : (
                        ''
                      )}

                      <div>Official Stamp of Customer</div>
                    </div>
                  </div>
                </div>
                <div className="over_hid">
                  {/* <div className="signate float_img">
                                        {firstCaps !== "" ?
                                        <div style={{padding:'10px'}} className={Signcss}>{firstCaps+"  .  "}<span className="under_line">{secondBold}</span></div>
                                        :""}
                                        {imageData !== null?
                                        <div style={{padding:'10px'}}  className="signate_img"><img 
                                        src={(imageData !== null) ? "data:"+imageType+";base64," + encode(imageData) : ""}
                                        alt={"Company Seal"}/>
                                        </div>:""}
                                    </div>                                 */}
                  <div className="fleft width30 pad_t10">
                    <div className="">
                      <input
                        className="form_col w_85 b_b1s"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                    <div className="pad_5t">Signatures</div>
                  </div>
                  <div className="fleft width40 pad_t10">
                    <div className="">
                      <input
                        className="form_col w_85 b_b1s"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                    <div className="pad_5t">Designation of Agent Signature</div>
                  </div>
                  <div className="fleft width30 pad_t30">
                    Name{' '}
                    <input
                      className="form_col b_b1s"
                      type="text"
                      name=""
                      value=""
                    />
                  </div>
                </div>
                <div className="over_hid">
                  <div className="fleft width55 pad_t10">
                    Name{' '}
                    <input
                      className="form_col b_b1s width92per"
                      type="text"
                      name=""
                      value={illCafDataupdated[8849].value}
                      readOnly
                    />
                  </div>
                  <div className="flright pad_t10 mar_r20">
                    Date{' '}
                    <input
                      className="form_col b_b1s"
                      type="text"
                      name=""
                      value={illCafDataupdated[8850].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className=" pad_t10">
                  Witness{' '}
                  <input
                    className="form_col b_b1s wid50"
                    type="text"
                    name=""
                    value={illCafDataupdated[8852].value}
                    readOnly
                  />
                </div>
                <div className="pad_t10">
                  Name{' '}
                  <input
                    className="form_col b_b1s wid50"
                    type="text"
                    name=""
                    value={illCafDataupdated[8853].value}
                    readOnly
                  />
                </div>
                <div className="pad_t10">
                  Address{' '}
                  <input
                    className="form_col b_b1s w_85"
                    type="text"
                    name=""
                    value=""
                  />
                  <div className="pad_5t">
                    <input
                      className="form_col b_b1s widthfull"
                      type="text"
                      name=""
                      value={illCafDataupdated[8854].value}
                      readOnly
                    />{' '}
                  </div>
                  <div className="pad_5t">
                    <input
                      className="form_col b_b1s widthfull"
                      type="text"
                      name=""
                      value=""
                    />{' '}
                  </div>
                </div>
                <div className="over_hid">
                  <div className="fleft width70 pad_t10">
                    <div className="">
                      <div className="">
                        <input
                          className="form_col b_b1s"
                          type="text"
                          name=""
                          value=""
                        />
                      </div>
                      <div className="pad_5t">
                        Signatures of volantries Signatures
                      </div>
                    </div>
                    <div className=" pad_t30">
                      Name{' '}
                      <input
                        className="form_col b_b1s wid50"
                        type="text"
                        name=""
                        value={illCafDataupdated[8858].value}
                        readOnly
                      />
                    </div>
                    <div className=" pad_t10">
                      Place{' '}
                      <input
                        className="form_col b_b1s wid50"
                        type="text"
                        name=""
                        value={illCafDataupdated[8859].value}
                        readOnly
                      />
                    </div>
                    <div className=" pad_t10">
                      Date{' '}
                      <input
                        className="form_col b_b1s wid50"
                        type="text"
                        name=""
                        value={illCafDataupdated[8860].value}
                        readOnly
                      />
                    </div>
                    <div className=" pad_t10">
                      Witness{' '}
                      <input
                        className="form_col b_b1s wid50"
                        type="text"
                        name=""
                        value={illCafDataupdated[8861].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="flright width30">
                    <div
                      style={{ padding: '5px', height: '120px' }}
                      className="signate"
                    >
                      {imageData !== null && imageData !== '' ? (
                        <img
                          height="90px"
                          width="100px"
                          src={
                            imageData !== null
                              ? 'data:' +
                                imageType +
                                ';base64,' +
                                encode(imageData)
                              : ''
                          }
                          alt={'Company Seal'}
                        />
                      ) : (
                        ''
                      )}
                      <div>Official Stamp of Customer</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2 font8">
              <p className="customfom">Product Form - Internet Leased Line</p>
              <div className=" pad_t10">
                Witness{' '}
                <input
                  className="form_col b_b1s wid50"
                  type="text"
                  name=""
                  value={illCafDataupdated[8862].value}
                  readOnly
                />
              </div>
              <div className="pad_t10">
                Name{' '}
                <input
                  className="form_col b_b1s w_85"
                  type="text"
                  name=""
                  value={illCafDataupdated[8863].value}
                  readOnly
                />
              </div>
              <div className="pad_t10">
                Address{' '}
                <input
                  className="form_col b_b1s w_85"
                  type="text"
                  name=""
                  value={illCafDataupdated[8864].value}
                  readOnly
                />
                <div className="pad_5t">
                  <input
                    className="form_col b_b1s widthfull"
                    type="text"
                    name=""
                    value=""
                  />{' '}
                </div>
                <div className="pad_5t">
                  <input
                    className="form_col b_b1s widthfull"
                    type="text"
                    name=""
                    value=""
                  />{' '}
                </div>
              </div>
              <p>
                Vodafone Idea Ltd, office use only
                <br />
                <div className="signate float_img">
                  {firstCaps !== '' ? (
                    <div style={{ padding: '10px' }} className={Signcss}>
                      {firstCaps + '  .  '}
                      <span className="under_line">{secondBold}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {imageData !== null && imageData !== '' ? (
                    <div style={{ padding: '10px' }} className="signate_img">
                      <img
                        src={
                          imageData !== null
                            ? 'data:' +
                              imageType +
                              ';base64,' +
                              encode(imageData)
                            : ''
                        }
                        alt={'Company Seal'}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                Vodafone Idea Ltd. and (Vodafone Idea Logo) are Registered Trade
                Mark of VIL and are used under License. VIL of its subsidiaries/
                principal entities may not operate or provide any all the
                Services under this Agreement and the Services may be offered by
                VIL under license and managed services agreement with
                specialized venders and any warranties or representations of the
                third party vendor are transferred to the Customer on as is
                basis and no additional warranties or representation or service
                level agreements are provided here in. The Customer and VIL and
                End User and/or person named in the CAF as subscriber/ Customer
                is under contract with VIL Entity providing the Services.
              </p>
              <div className="connectcol">
                <p className="ready">
                  Connect your world to <strong>become a Ready Business</strong>
                </p>
                <div className="connecticons">
                  <span>
                    <img src={WireImg} />
                    <p>Wire</p>
                  </span>
                  <span>
                    <img src={WirelessImg} />
                    <p>Wireless</p>
                  </span>
                  <span>
                    <img src={lotImg} />
                    <p>IoT</p>
                  </span>
                  <span>
                    <img src={CloudImg} />
                    <p>Cloud</p>
                  </span>
                </div>
                <p className="url">www.vodafone.in/business</p>
              </div>
            </div>
          </page>
          {excelJson !== null ? (
            <page size="A4">
              <div>
                <div className="pad_t10 section2">
                  <p className="customfom">Annexure to ILL CAF :- </p>
                </div>
                <JsonToTable json={excelJson} />
                <div>
                  <p class="font10 " style={{ marginTop: '20px' }}>
                    <strong>Note:</strong> Address proofs will be required for
                    each of the sites listed above as part of standard
                    documentation guidelines; Master CAF referred here should be
                    signed by the same
                  </p>
                </div>

                <div class="fleft width50 pad_t10">
                  <p class="marb5">Account Manager Name</p>
                  <p class="margin0">
                    <input
                      class="form_col widthfull inputext"
                      type="text"
                      name="Account Manager Name"
                      readonly=""
                      value={illCafDataupdated[8826].value}
                    />
                  </p>
                  <div className="signate" style={{ position: 'relative' }}>
                    <div className="signate_img input_sign">
                      {acctMngSignImageData !== null &&
                      acctMngSignImageData !== '' ? (
                        <img
                          src={
                            acctMngSignImageData !== null
                              ? 'data:' +
                                imageType +
                                ';base64,' +
                                encode(acctMngSignImageData)
                              : ''
                          }
                          alt={'Account Manager Sign'}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    <div>Signature of the Account Manager </div>
                  </div>
                </div>
                <div
                  className="signate float_img"
                  style={{ position: 'initial' }}
                >
                  <div className="over_hid ">
                    <div className="over_hid ">
                      Authorised Signatory Name
                      <input
                        className="form_col inputext"
                        type="text"
                        name="Name_CD"
                        value={illCafDataupdated[8821].value}
                        readOnly
                      />
                    </div>
                  </div>
                  {firstCaps !== '' ? (
                    <div style={{ padding: '10px' }} className={Signcss}>
                      {firstCaps + '  .  '}
                      <span className="under_line">{secondBold}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {imageData !== null && imageData !== '' ? (
                    <div style={{ padding: '10px' }} className="signate_img">
                      <img
                        src={
                          imageData !== null
                            ? 'data:' +
                              imageType +
                              ';base64,' +
                              encode(imageData)
                            : ''
                        }
                        alt={'Company Seal'}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </page>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  } else {
    illCafContentView = cafData ? (
      <div>
        <Center p={2} my={1}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor={chakraTheme.colors.secondary}
            color={chakraTheme.colors.brand[800]}
            size="md"
          />
        </Center>
      </div>
    ) : (
      <div style={{ marginTop: '200px', height: '300px', textAlign: 'center' }}>
        {' '}
        CAF Form not submitted yet.
      </div>
    );
  }
  return <div>{illCafContentView}</div>;
};

export default IllCafForm;
